import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/alirun/Work/Blockeys/ofi-landing/src/layouts/academyPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "call-option",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#call-option",
        "aria-label": "call option permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Call Option:`}</h1>
    <p>{`Call option is a financial contract that gives the option buyer the right, but not the obligation, to buy an underlying asset at a specified price within a specific time period from several months to a year. A call buyer profits when the underlying asset increases in price as they can purchase this asset at a lower price and resell it at a current market price, earning the difference.  `}</p>
    <h3 {...{
      "id": "opium-call-option-coming-soon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#opium-call-option-coming-soon",
        "aria-label": "opium call option coming soon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`OPIUM Call Option (coming soon)`}</strong></h3>
    <p>{`Purchase call option on the OPIUM governance token. If the price of $OPIUM is above the strike price by the end of the option period, the buyer receives the full price difference between the current price and the strike price without the need to buy and then resell the token.`}</p>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <p><em parentName="p">{`$OPIUM price is 6 USD today and you buy (let’s say for ? USD) 100 option calls that have a strike price of 7 USD and mature within a year.
Your calculation is that the OPIUM token will increase in price notably and you will earn the difference between the strike price and the actual price of $OPIUM when a year is over. Let’s say that by the end of the year $OPIUM is 15 USD which is 8 USD above the strike price; this means your option calls will be burned and pay you 8 USD each or 800 USD worth of $OPIUM tokens in total.`}</em></p>
    <p><em parentName="p">{`However, if the OPIUM token drops in price within a year and costs for example 5.50 USD, you are not obliged to proceed with the purchase.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      